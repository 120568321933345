import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  linkExactActiveClass: 'active',
  routes: [
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      children: [
        // Dashboard
        {
          name: 'Dashboard',
          path: '',
          component: () => import('@/views/dashboard/Main'),
        },
        // Pages
        {
          name: 'Absensi',
          path: 'wfh/absen',
          component: () => import('@/views/wfh/absen'),
        },
        {
          name: 'Laporan',
          path: 'repwork',
          component: () => import('@/views/wfh/laporan/index'),
          children: [
            {
              path: 'crwfh',
              component: () => import('@/views/wfh/laporan/create'),
              name: 'Buat Laporan Pekerjaan',
              meta: { name: 'Buat Laporan Pekerjaan' },
              hidden: true },
            {
              path: 'upwfh/:id',
              component: () => import('@/views/wfh/laporan/edit'),
              name: 'Ubah Laporan Pekerjaan',
              meta: { name: 'Ubah Laporan Pekerjaan',
                noCache: true },
              hidden: true },
            {
              path: 'list',
              component: () => import('@/views/wfh/laporan/list'),
              name: 'List Laporan Pekerjaan',
              meta: { name: 'List Laporan' }
            }
          ]
        }
      ],
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/',
      redirect: 'login',
      component: () => import('@/views/auth/Index'),
      children: [
      {
        path: '/login',
        name: 'login',
        component: () => import('@/views/auth/Login'),
        meta: {
          guest: true,
        },
      },
      ],
    },
    {
      path: '/logout',
      component: () => import('@/views/auth/Logout'),
      hidden: true
    }
  ],
})
router.beforeEach((to, from, next) => {
  // console.log('beforeEach')
  // console.log(to)
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // console.log(localStorage.getItem('jwt'))
    if (localStorage.getItem('jwt') == null) {
      // console.log('not logged')
      next({
          path: '/login',
          params: { nextUrl: to.fullPath },
      })
    } else {
        const user = JSON.parse(localStorage.getItem('user'))
        if (to.matched.some(record => record.meta.is_admin)) {
            if (user.is_admin === 1) {
                next()
            } else {
              next({ name: 'dashboard' })
            }
        } else {
            next()
        }
    }
  } else {
    next()
  }
})

export default router
